export enum LisaMenu {
  Main = 201,
  Sportsbook = 205,
  Casino = 206,
  CasinoLive,
  Virtual,
  MeNavigation,
  MeOverview,
  MeHistory,
  MeBalance,
  AccountNetwork,
  AccountReports,
  BottomMenuAnonymous,
  BottomMenuSportsbook,
  BottomMenuGames,
  Jackpot = 218,
}

export enum MaggieMenu {
  Main= 301,
  SiteMap,
  Services,
  Information,
  Sportsbook,
  Casino,
  CasinoLive,
  Virtual,
  MeNavigationLogged,
  MeOverviewLogged,
  AccountNetwork = 313,
  AccountReports = 314,
  CrashGames= 315
}

export enum PattyMenu {
  Main = 301,
  Sport,
  Casino,
  CasinoLive,
  Virtual,
  Account,
  Jackpot = 218, // todo CHANGE taking lisa ones
}

export enum WinchesterMenu {
  Main = 501,
  PlayerPage = 508,
  AgentPage = 509,
  ShopPage = 510,
  BookmakerPage = 511,
  OperatorPage = 512,
  CashierPage
}

export enum NedMenu {
  Main = 601,
  PlayerPage = 608,
  AgentPage = 609,
  ShopPage = 610,
  BookmakerPage = 611,
  OperatorPage = 612
}

export enum SelmaMenu {
  Main = 601,
  AccountNetwork = 602,
  AccountReports
}

export enum BartMenu {
  Main = 801,
  Sportsbook = 805,
  Casino = 806,
  CasinoLive,
  Virtual,
  MeNavigation,
  MeOverview,
  MeHistory,
  MeBalance,
  AccountNetwork,
  AccountReports,
  BottomMenuAnonymous,
  BottomMenuSportsbook,
  BottomMenuGames,
  Jackpot = 818
}
